<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <SponsorForm
      v-if="!isLoading"
      :sponsor="sponsor"
      :imageSrc="imageSrc"
      :submitName="$t('edit')"
      v-on:addOrUpdateSponsor="addOrUpdateSponsor"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS, BASE_URL } from "@/utils/constants";
import Sponsor from "@/models/sponsors/Sponsor";
import SponsorForm from "@/components/sponsors/SponsorForm.vue";
import DEFAULT_IMG from "@/assets/images/sponsors.svg";

export default {
  name: "SponsorEdit",
  components: {
    PreLoader,
    ExceptionWithImg,
    SponsorForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  props: ["sponsorToken"],
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      sponsor: new Sponsor(),
      imageSrc: DEFAULT_IMG,
    };
  },
  methods: {
    async getSponsorDetails() {
      this.isLoading = true;
      this.sponsor.sponsorToken = this.sponsorToken;

      try {
        const response = await this.sponsor.getSponsorDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.sponsor.fillData(response.data.sponsors);
          this.imageSrc = BASE_URL + this.sponsor.sponsorImagePath;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "Sponsors" }).catch(() => {});
        }
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.$router.push({ name: "Sponsors" }).catch(() => {});
      }
      this.isLoading = false;
    },
    async addOrUpdateSponsor() {
      this.isLoading = true;
      try {
        const response = await this.sponsor.addOrUpdateSponsor(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.sponsor.setInitialValue();
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "Sponsors" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    showMsg(msg = "", isSuccess = false) {
      let variant = isSuccess ? "success" : "danger";
      this.$bvToast.toast(`${msg}`, {
        title: this.$t("alert"),
        variant: variant,
        toaster: "b-toaster-bottom-center",
        autoHideDelay: 5000,
      });
    },
  },
  async created() {
    this.getSponsorDetails();
  },
};
</script>

<style scoped lang="scss"></style>
